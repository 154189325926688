import GlobalStyles from "@styles/GlobalStyles";
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "@aws-amplify/ui-react/styles.css";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import Loader from "@components/common/Loader";
import Main from "@components/Main";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      retry: false
    }
  }
});

function App() {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        fallbackRender={error => {
          console.log(error);
          return <div>There was an Error!</div>;
        }}
        onReset={reset}
      >
        <Suspense fallback={<Loader />}>
          <GlobalStyles />
          <Main />
        </Suspense>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
