import { Select as AntSelect } from "antd";
import { SelectProps } from "antd/lib/select";
import Icon from "@ant-design/icons";
import { ReactComponent as ArrowUp } from "@assets/images/arrow-up.svg";
import { SelectValue } from "antd/lib/select";
import styled, { CSSProperties } from "styled-components";

const StyledSelect = styled(AntSelect)`
  .ant-select-selector {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
      0px 5px 4px -4px rgba(0, 0, 0, 0.02) !important;
    border-radius: 8px !important;
    height: 48px !important;
    width: 164px !important;
    align-items: center;
    padding: 0px 12px !important;
    color: var(--text-primary);

    &:hover,
    :focus,
    :focus-within,
    :active {
      border: 2px solid var(--primary-color) !important;
      transition: none !important;
      padding: 0 11px !important;
    }
  }
  // arrow icon 바꿔주면 arrow 클릭이 안되는 버그가 있음. 아래로 해결.
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }
  .ant-select-arrow {
    padding-right: 2px;
    font-size: 14px;
    transform: rotateX(180deg);
    svg path {
      fill: var(--border-dark);
    }
  }

  &:hover,
  &:focus-within .ant-select-arrow {
    svg path {
      fill: var(--text-primary) !important;
    }
  }

  // dropdown open시, 화살표 방향 바꾸고 색 바꾸기.
  &.ant-select-open {
    svg {
      transform: rotateX(180deg);
      path {
        fill: var(--text-primary);
      }
    }
  }
` as typeof AntSelect;

const dropdownStyle = {
  border: "1px solid var(--border-dark)",
  borderRadius: "8px",
  boxShadow:
    "0px 12px 12px -8px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
  fontSize: "14px",
  fontWeight: 550
};

const Select = <T extends SelectValue>({
  children,
  style,
  ...props
}: SelectProps<T>) => (
  <StyledSelect
    {...props}
    suffixIcon={<Icon component={ArrowUp} />}
    dropdownStyle={dropdownStyle}
    style={{ ...style } as CSSProperties}
  >
    {children}
  </StyledSelect>
);

Select.Option = AntSelect.Option;

export default Select;
