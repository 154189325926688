import * as styled from "styled-components";
import { reset } from "./reset";

const GlobalStyles = styled.createGlobalStyle`
  ${reset}
  body {
    --primary-color: #040435;

    --mono-0: #fff;

    --text-primary: #2b2e33;
    --text-secondary: #6c7380;

    --background-color: #f8f8fa;
    --border-dark: #bec2cc;
    --border-light: #eaeaea;

    font-family: "Inter", --apple-system, "Segoe UI", sans-serif;
  }
`;

export default GlobalStyles;
