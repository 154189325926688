import Icon from "@ant-design/icons";
import { ReactComponent as Spin } from "@assets/images/loader.svg";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 256px;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }
  svg {
    animation: spin 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;

export default function Loader() {
  return (
    <LoaderWrapper>
      <Icon component={Spin} style={{ fontSize: "48px" }} />
    </LoaderWrapper>
  );
}
