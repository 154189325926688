/* eslint-disable react/display-name */
import { ReactNode } from "react";
import { Layout as AntdLayout } from "antd";
import styled from "styled-components";
import LogoImage from "@assets/images/logo.svg";
import Logo from "@components/common/Logo";
import History from "@components/History";

const { Header, Content, Footer } = AntdLayout;

const Layout = styled(AntdLayout)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  min-width: 1144px;
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  font-size: 12px;
  color: #ababab;
  margin-top: auto;
  padding: 0;
  background-color: var(--background-color);
`;

const StyledHeader = styled(Header)`
  width: 100%;
  background-color: var(--mono-0);
  z-index: 1000;
  position: relative;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px;

  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledContent = styled(Content)`
  width: 100%;
  min-height: 100%;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #323232;
    text-decoration: revert;
  }
`;

function Page({ children }: { children: ReactNode }) {
  return (
    <Layout>
      <StyledHeader>
        <Wrapper>
          <Logo
            image={LogoImage}
            title="SpliceVi"
            color="var(--primary-color)"
            alt="SpliceVi-logo-image"
          />
          <History />
        </Wrapper>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
      <StyledFooter>
        Copyright &#9400;{" "}
        <Link
          href="https://3billion.io"
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          3billion
        </Link>
        . All rights reserved.
      </StyledFooter>
    </Layout>
  );
}

export default Page;
