import styled, { css } from "styled-components";
import { Image, Table as AntdTable } from "antd";
import Loader from "@components/common/Loader";
import Card from "@components/common/Card";
import { CloseButton } from "@components/common/Buttons";
import { FormData } from "@utils/types";
import useScoreColumns from "@components/SpliceVi/useScoreColumns";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import Error3 from "@assets/images/error-x-3rd.svg";
import Warning3 from "@assets/images/warning-3rd.svg";

export interface ResultProps {
  hash: string;
  input: FormData;
  onClose: (hash: string, intervalId?: NodeJS.Timer) => void;
}

const Wrapper = styled(Card)`
  min-height: 256px;
  width: 1144px;
  margin-bottom: 12px;
  position: relative;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  grid-area: title;
  font-size: 12px;
  padding: 16px;
  border-bottom: 1px solid var(--border-light);
  display: flex;
  gap: 40px;
  color: #323232;
  letter-spacing: -0.04px;
`;

const Span = styled.div`
  display: flex;
  gap: 12px;
  b {
    font-weight: 600;
  }
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Img = styled(Image)`
  padding: 24px 0 12px 0;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

const Table = styled(AntdTable)`
  min-width: 282px;
  padding: 4px 12px 12px 12px;
  border-left: 1px solid var(--border-light);

  & thead,
  tr,
  th {
    vertical-align: middle;
    font-size: 12px;
  }

  thead tr th {
    font-weight: 600;
    &::before {
      display: none;
    }
  }

  tbody tr {
    &:last-child td {
      border-bottom: none !important;
    }
  }
` as typeof AntdTable;

const Button = styled(CloseButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const IconWrapper = styled.div<{ type: string }>`
  margin: auto;

  ${({ type }) => {
    switch (type) {
      case "warning":
        return css`
          padding: 0 24px;
        `;
      case "error":
        return css`
          padding-top: 50px;
        `;
      default:
        return css`
          padding: 0;
        `;
    }
  }}
`;

const Icon = styled.div`
  margin: auto;
  height: 60px;
  width: 60px;

  @keyframes pulse {
    0% {
      transform: scale(0.6);
    }
    30% {
      transform: scale(0.3);
    }
    100% {
      transform: scale(0.8);
    }
  }

  img {
    animation: pulse 0.8s forwards;
  }
`;

function SubTitleItem({ item, value }: { item: string; value: any }) {
  return (
    <Span>
      {item}
      <b>{value}</b>
    </Span>
  );
}

function Inputs({
  input,
  isError = false
}: {
  input: FormData;
  isError?: boolean;
}) {
  return (
    <>
      {isError && (
        <SubTitleItem
          item="Variant"
          value={input.variant.replaceAll("-", ":")}
        />
      )}
      <SubTitleItem item="Genome" value={input.grch} />
      <SubTitleItem item="Max distance" value={`${input.distance}bp`} />
      <SubTitleItem item="Score type" value={input.mask} />
      <SubTitleItem item="Illumina score" value={input.illumina} />
    </>
  );
}

function Result({ hash, input, onClose }: ResultProps) {
  const columns = useScoreColumns();
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [exceed, setExceed] = useState(false);

  const { data, error, isLoading, refetch } = useQuery(
    [hash],
    async () =>
      await axios
        .get(
          `https://0g1o1niay2.execute-api.ap-northeast-2.amazonaws.com/prod/${hash}`
        )
        .then(res => res.data)
  );

  useEffect(() => {
    if (error || data !== "loading") {
      clearInterval(intervalId);
      setIntervalId(undefined);
    } else if (data === "loading" && !intervalId) {
      let counter = 0;
      const id = setInterval(() => {
        counter += 1; // max trial === 100
        if (counter === 100) {
          clearInterval(id);
          setIntervalId(undefined);
          setExceed(true);
        }
        refetch();
      }, 3000); // every 3 sec
      setIntervalId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <Wrapper>
      {(error || exceed) && (
        <Contents>
          <Title>
            <Inputs input={input} isError={true} />
          </Title>
          <IconWrapper type="error">
            <Icon>
              <img src={Error3} alt="error-icon3" />
            </Icon>
            Something went wrong... Please try again.
          </IconWrapper>
        </Contents>
      )}
      {(isLoading || data === "loading") && !error && <Loader />}
      {data && data !== "loading" && (
        <Contents>
          <Title>
            <SubTitleItem
              item="Variant"
              value={data.variant.replaceAll("-", ":")}
            />
            <SubTitleItem
              item="Gene"
              value={`${data.gene} (${data.transcript})`}
            />
            <Inputs input={input} />
          </Title>
          <Data>
            {data.png ? (
              <Img src={data.png} />
            ) : (
              <IconWrapper type="warning">
                <Icon>
                  <img src={Warning3} alt="warning-icon3" />
                </Icon>
                {data.result}
              </IconWrapper>
            )}
            <Table
              rowKey="type"
              dataSource={data.scores}
              columns={columns}
              pagination={false}
              size="small"
            />
          </Data>
        </Contents>
      )}
      <Button onClick={() => onClose(hash, intervalId)} />
    </Wrapper>
  );
}

export default Result;
