import styled from "styled-components";
import { Form as AntdForm, Input as AntdInput } from "antd";
import Icon from "@ant-design/icons";
import { ArrowButton } from "@components/common/Buttons";

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled(AntdForm)`
  height: 102px;
  margin-top: -51px;
  margin-bottom: 12px;
  background-color: var(--mono-0);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.04) !important;
  border-radius: 8px;
  padding: 10px 16px 16px 16px;
  font-size: 16px;
  width: 1144px;

  display: flex;
  gap: 10px;

  .ant-form-item-label {
    padding-bottom: 6px;

    & > label {
      font-weight: 500;
      color: var(--primary-color);
      font-size: 12px;
      height: fit-content;
    }
  }
` as typeof AntdForm;

export const Item = styled(AntdForm.Item)`
  margin: 0;

  &:last-child {
    align-self: end;
  }

  .ant-form-item-explain-error {
    padding-left: 2px;
    font-size: 12px !important;
  }
`;

export const NoDataImage = styled.img`
  margin: 0 auto;
  padding-top: 8%;
`;

export const Input = styled(AntdInput)`
  border: 1px solid var(--border-dark);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  height: 48px;
  &::placeholder {
    color: var(--text-secondary);
  }
  padding: 12px;

  &:focus,
  :hover,
  :active {
    border: 2px solid var(--primary-color);
    transition: none !important;
    padding: 11px !important;
  }
`;

export const Submit = styled(ArrowButton)`
  height: 48px;
  width: 164px;
`;

export const ItemLabel = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Info = styled(Icon)`
  font-size: 16px;
  &:hover {
    svg path {
      fill: var(--primary-color);
    }
  }
`;
