import styled, { css } from "styled-components";
import Icon from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { ReactComponent as CloseX } from "@assets/images/close-x.svg";
import { ReactComponent as ArrowRight } from "@assets/images/arrow-right.svg";
import { ReactComponent as ArrowRightFill } from "@assets/images/arrow-right-fill.svg";

const StyledClose = styled(Button)`
  &:hover,
  :focus,
  :focus-within,
  :active {
    svg path {
      fill: #323232;
    }
  }
`;

const StyledButton = styled(Button)<{ type: string }>`
  ${({ type }) =>
    ["primary", "link"].includes(type) &&
    css`
      border-radius: 8px;
      background-color: var(--primary-color);
      color: var(--mono-0);
      font-weight: 600;
    `}

  &:hover,
  :focus,
  :focus-within,
  :active {
    ${({ type }) =>
      ["primary", "link"].includes(type) &&
      css`
        background-color: #002980;
        border-color: #002980;
        color: var(--mono-0);
      `}

    .anticon {
      transition: transform 300ms ease-in-out;
      transform: translate(6px, 0);
    }
  }
`;

export function CloseButton({ children, ...rest }: ButtonProps) {
  return (
    <StyledClose type="text" icon={<Icon component={CloseX} />} {...rest}>
      {children}
    </StyledClose>
  );
}

export function ArrowButton({
  children,
  type = "primary",
  ...rest
}: ButtonProps) {
  return (
    <StyledButton type={type} {...rest}>
      {children}
      <Icon
        component={
          ["primary", "link"].includes(type) ? ArrowRight : ArrowRightFill
        }
      ></Icon>
    </StyledButton>
  );
}
