import styled from "styled-components";
import SpliceVi from "@components/SpliceVi";
import Page from "@layout/Page";
import SplicingImage from "@assets/images/splicing.svg";

const HeroWrapper = styled.div`
  width: 100%;
  background: linear-gradient(272.12deg, #162138 7.76%, #002b80 106.63%);
`;

const Hero = styled.div`
  height: 280px;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeroText = styled.div`
  width: 550px;
  font-size: 22px;
  font-weight: 700;
  color: #93c5fd;
  padding-bottom: 50px;
`;

const HeroImage = styled.img`
  padding: 0 10px 24px 24px;
`;

function Main() {
  return (
    <Page>
      <HeroWrapper>
        <Hero>
          <HeroImage src={SplicingImage} alt="splicing-pictogram" />
          <HeroText>
            <span style={{ color: "var(--mono-0)" }}>SpliceVi,</span> Enter an
            SNV below to see its predicted consequences based on SpliceAI
            scores.
          </HeroText>
        </Hero>
      </HeroWrapper>
      <SpliceVi />
    </Page>
  );
}

export default Main;
