import { useState, useRef } from "react";
import Icon from "@ant-design/icons";
import styled from "styled-components";
import { CloseButton, ArrowButton } from "@components/common/Buttons";
import { ReactComponent as Bell } from "@assets/images/bell.svg";
import { ReactComponent as BellFill } from "@assets/images/bell-fill.svg";
import { ReactComponent as Mark } from "@assets/images/exclamation-mark.svg";
import useOnClickOutside from "@components/useOnClickOutside";

const Notifications = styled(Icon)`
  font-size: 24px;
`;

const Wrapper = styled.aside`
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 12px 12px -8px rgba(0, 0, 0, 0.08),
    0px 0px 4px rgba(0, 0, 0, 0.04);
  border: 1px solid #e4e6eb;
  border-radius: 12px 0px 0px 12px;
  color: var(--text-primary);

  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0.5;
    }
    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  animation: slide-in 800ms;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 15px 20px;
  border-bottom: 1px solid #f0f2f5;
`;

const Request = styled.div`
  margin: 0 24px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Relations = styled.div`
  margin: 0 24px;
  padding: 24px;
  display: flex;
  gap: 14px;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 16px;
`;

const Notes = styled.div`
  margin: 0 24px;
  font-size: 12px;
  color: var(--text-secondary);
  line-height: 170%;

  .anticon {
    vertical-align: -0.35em;
  }
`;

const EmailButton = styled(ArrowButton)`
  height: 40px;
  width: 100%;

  span {
    padding-top: 4px !important;
  }
`;

const Link = styled.a`
  height: 40px;
  width: 100%;
  border: 1px solid #e4e6eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  background-color: var(--mono-0);
  padding: 10px;
  text-align: center;
  font-weight: 600;

  &:hover,
  :active,
  :focus,
  :focus-within {
  }
`;

function History() {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  return (
    <div ref={wrapperRef}>
      <Notifications
        component={hover ? BellFill : Bell}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={() => setOpen(true)}
        title="notification"
      />
      {open && (
        <Wrapper>
          <Title>
            {`What's new`}
            <CloseButton onClick={() => setOpen(false)} />
          </Title>
          <Request>
            To post issues or feature requests, please use
            <EmailButton type="link" href="mailto:ukcho@3billion.io">
              Send email
            </EmailButton>
          </Request>
          <Relations>
            <span style={{ fontSize: "16px", fontWeight: "500" }}>
              Related web tools
            </span>
            <Link
              href="https://spliceailookup.broadinstitute.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SpliceAI Lookup
            </Link>
          </Relations>
          <Notes>
            <Icon
              component={Mark}
              style={{ paddingRight: "4px", fontSize: "16px" }}
            />
            This website uses results from{" "}
            <a
              href="https://spliceailookup-api.broadinstitute.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SpliceAI API
            </a>{" "}
            and MANE transcript from{" "}
            <a
              href="https://www.ncbi.nlm.nih.gov/refseq/MANE"
              target="_blank"
              rel="noopener noreferrer"
            >
              RefSeq
            </a>{" "}
            for predicting potential splicing alteration.
            <br />
            For citation:{" "}
            <i>
              SpliceVi, 3billion. URL:{" "}
              <a
                href="https://splicevi.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://splicevi.io
              </a>
            </i>
            <br />
            <br />
            Released: 2022/10/25
            <br />
            Last updated: 2022/10/25
          </Notes>
        </Wrapper>
      )}
    </div>
  );
}

export default History;
