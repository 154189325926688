import styled from "styled-components";
import { SpliceAIScores } from "@utils/types";
import { ColumnsType } from "antd/lib/table";

const Score = styled.span<{ score: number }>`
  color: ${({ score }) =>
    score < 0.2 ? "#6ecb90" : score >= 0.8 ? "#ff142d" : "inherit"};
`;

const DisplayType: Record<string, string> = {
  al: "Acceptor Loss",
  ag: "Acceptor Gain",
  dg: "Donor Gain",
  dl: "Donor Loss"
};

function useScoreColumns() {
  const columns: ColumnsType<SpliceAIScores> = [
    {
      title: "ΔType",
      dataIndex: "type",
      key: "type",
      render: record => DisplayType[record]
    },
    {
      title: "ΔScore",
      dataIndex: "score",
      key: "score",
      render: record => {
        const fixedScore = parseFloat(record).toFixed(2);

        return (
          <Score score={record}>
            {Number(fixedScore) === 0 ? 0 : fixedScore}
          </Score>
        );
      }
    },
    {
      title: "Distance",
      dataIndex: "bp",
      key: "bp",
      render: record => `${record} bp`
    }
  ];

  return columns;
}

export default useScoreColumns;
