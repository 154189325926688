import styled from "styled-components";

export interface LogoProps {
  title: string;
  image?: string;
  color?: string;
  alt?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const LogoText = styled.h3`
  font-weight: 550;
  font-size: 28px;
  color: ${props => props.color};
  letter-spacing: -0.7px;
  line-height: 22px;
  margin: 2px 0 0 0;
`;

function Logo({ title, color = "black", image, alt }: LogoProps) {
  return (
    <Wrapper className="logo">
      {!!image && <img src={image} alt={alt} />}
      {<LogoText color={color}>{title}</LogoText>}
    </Wrapper>
  );
}

export default Logo;
