import { useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as CloseX } from "@assets/images/close-x.svg";
import { ArrowButton } from "@components/common/Buttons";

const Button = styled(ArrowButton)`
  font-size: 12px;
  color: var(--color-primary);
  font-weight: 600;
  padding: 0;
  height: max-content;
`;

function Examples() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button type="text" onClick={() => setOpen(true)}>
        <span>See examples</span>
      </Button>
      <Modal
        title="Examples"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        centered
        closeIcon={<Icon component={CloseX} />}
      >
        <p>
          Only SNVs are allowed.
          <br />
          <br />
          <b>Format:</b>
          <br />
          [chromosome]-[genomic position]-[reference allele]-[alternative
          allele]
          <br />
          <br />
          An example (on hg37):
          <br />
          21-47541468-A-G
        </p>
      </Modal>
    </>
  );
}

export default Examples;
