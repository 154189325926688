import { Tooltip as AntTooltip, TooltipProps } from "antd";

const Tooltip = ({ children, ...rest }: TooltipProps) => (
  <AntTooltip
    placement="top"
    color="#2c2c2e"
    overlayInnerStyle={{
      borderRadius: "6px",
      fontWeight: "500",
      fontSize: "12px"
    }}
    {...rest}
  >
    {children}
  </AntTooltip>
);

export default Tooltip;
