import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Form, message } from "antd";
import { ReactComponent as InfoIcon } from "@assets/images/info.svg";
import Nodata from "@assets/images/no-data.svg";
import Select from "@components/common/Select";
import Tooltip from "@components/common/Tooltip";
import { FormData } from "@utils/types";
import Result from "./Result";
import Examples from "./Examples";
import * as S from "./styles";

const { Option } = Select;

interface Hash {
  input: FormData;
  hash: string;
}

const TooltipSentences = {
  distance: () => (
    <>
      The size of a window that SpliceAI looks to see how the variant affects
      other positions being splicing motifs. Values range from 50bp to 10,000bp.
      More detailed explanation, go to{" "}
      <a
        href="https://spliceailookup.broadinstitute.org"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "underline" }}
      >
        https://spliceailookup.broadinstitute.org
      </a>
    </>
  ),
  mask: () => (
    <>
      Selecting &apos;masked&apos; will hide the score for strengthening
      annotated splice sites and weakening unannotated splice sites and show 0
      instead. Selecting &apos;raw&apos; will show all scores. More detailed
      explanation, go to{" "}
      <a
        href="https://spliceailookup.broadinstitute.org"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "underline" }}
      >
        https://spliceailookup.broadinstitute.org
      </a>
    </>
  ),
  illumina: () => (
    <>
      Illumina provides pre-computed SpliceAI scores for all SNPs and small
      InDels within Gencode v24 canonical transcripts. More detailed
      explanation, go to{" "}
      <a
        href="https://spliceailookup.broadinstitute.org"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "underline" }}
      >
        https://spliceailookup.broadinstitute.org
      </a>
    </>
  )
};

function SpliceVi() {
  const [form] = Form.useForm<FormData>();
  const [currentInput, setCurrentInput] = useState<FormData | null>(null);
  const [hashes, setHashes] = useState<Hash[]>([]);

  const { data, error } = useQuery(
    [currentInput],
    async () =>
      await axios
        .post(
          "https://0g1o1niay2.execute-api.ap-northeast-2.amazonaws.com/prod/",
          currentInput
        )
        .then(res => res.data),
    {
      onError: err =>
        message.error({
          content: `Submit Error!(${err}) Please try again.`,
          style: { marginTop: "10vh" },
          duration: 2
        }),
      enabled: !!currentInput
    }
  );

  useEffect(() => {
    if (currentInput && data) {
      setHashes(prev => [{ input: currentInput, hash: data }, ...prev]);
      setCurrentInput(null);
    }
  }, [currentInput, data]);

  const queryClient = useQueryClient();

  const onClose = (hash: string, intervalId?: NodeJS.Timer) => {
    setHashes(value => value.filter(v => v.hash !== hash));
    queryClient.cancelQueries([hash]);
    clearInterval(intervalId);
  };

  const onSubmit = () => {
    setCurrentInput(form.getFieldsValue());
    form.resetFields();
    message.success({
      content: "Successfully submitted!",
      style: { marginTop: "10vh" },
      duration: 2
    });
  };

  return (
    <S.Wrapper>
      <S.Form
        labelCol={{ span: 24 }}
        colon={false}
        form={form}
        size="large"
        initialValues={{ grch: "GRCh37", mask: "raw", illumina: "no" }}
        onFinish={onSubmit}
      >
        <S.Item
          label={<Examples />}
          name="variant"
          style={{ flex: "1 0 242px" }}
          rules={[
            { required: true, message: "This field is required!" },
            {
              pattern:
                /^([1-9]|1[0-9]|2[012]|[XY]|MT)-([0-9]+)-([ATGC]{1})-([ATGC]{1})$/,
              message: "Invalid format. See examples."
            }
          ]}
        >
          <S.Input placeholder="1-1234-A-T" />
        </S.Item>
        <S.Item
          label={
            <S.ItemLabel>
              <span>Max distance</span>
              <Tooltip title={TooltipSentences["distance"]}>
                <S.Info component={InfoIcon} />
              </Tooltip>
            </S.ItemLabel>
          }
          name="distance"
          style={{ flex: "1 0 164px" }}
          rules={[
            { required: true, message: "This field is required!" },
            {
              pattern:
                /^([5-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|10000)$/,
              message: "Invalid range."
            }
          ]}
        >
          <S.Input placeholder="50~10000" />
        </S.Item>
        <S.Item label="Genome version" name="grch">
          <Select defaultValue="GRCh37">
            <Option value="GRCh37">GRCh37</Option>
            <Option value="GRCh38">GRCh38</Option>
          </Select>
        </S.Item>
        <S.Item
          label={
            <S.ItemLabel>
              <span>Score type</span>
              <Tooltip title={TooltipSentences["mask"]}>
                <S.Info component={InfoIcon} />
              </Tooltip>
            </S.ItemLabel>
          }
          name="mask"
        >
          <Select defaultValue="raw">
            <Option value="masked">masked</Option>
            <Option value="raw">raw</Option>
          </Select>
        </S.Item>
        <S.Item
          label={
            <S.ItemLabel>
              <span>Use illumina score</span>
              <Tooltip title={TooltipSentences["illumina"]}>
                <S.Info component={InfoIcon} />
              </Tooltip>
            </S.ItemLabel>
          }
          name="illumina"
        >
          <Select defaultValue="no">
            <Option value="yes">yes</Option>
            <Option value="no">no</Option>
          </Select>
        </S.Item>
        <S.Item>
          <S.Submit htmlType="submit" onClick={e => e.currentTarget.blur()}>
            Submit
          </S.Submit>
        </S.Item>
      </S.Form>
      {!hashes.length && <S.NoDataImage src={Nodata} alt="no-data-icon" />}
      {hashes.length > 0 &&
        hashes.map((hash, i) => (
          <Result
            key={i}
            hash={hash.hash}
            input={hash.input}
            onClose={onClose}
          />
        ))}
    </S.Wrapper>
  );
}

export default SpliceVi;
